
import gsap from "gsap";
import TextSlicer from 'text-slicer';

export function createStaggeredTextAppear() {
    setTimeout(() => {
        document.querySelectorAll('.text-slicer').forEach((element) => {
            const textSlicer = new TextSlicer({
                container: element,
                splitMode: 'both',
                cssVariables: true,
                dataAttributes: true,
            });

            textSlicer.init();
        });

        let targets = document.querySelectorAll('.char');

        gsap.from(targets, {
            y: 10 + (window.innerWidth / 100),
            opacity: 0,
            stagger: {
                each: 0.018,
                onComplete: function () {
                    let target = this.targets()[0];
                    target.classList.add('gsap-done');
                }
            }
        });
    }, 500);


    let bannerimg = document.querySelector('.bannerimg');
    gsap.from(bannerimg, {
        y: -10,
        x: -10,
        rotation: 30,
        opacity: 0,
        delay: 1,
        onComplete: function () {
            /* let target = this.targets()[0];
            target.classList.add('gsap-done'); */
        }
    });
}