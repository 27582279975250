import { createStaggeredTextAppear } from "../modules/staggeredText";

function initHome() {
    createStaggeredTextAppear();
}

document.addEventListener("DOMContentLoaded", () => {
    initHome();
});

